<template>
  <div class="invoices-table">
    <v-card>
      <v-card-title class="mb-0 pb-1">
        Invoice Details
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isInvoicesRefreshing"
                @click="refreshInvoices"
              />
            </v-col>
            <v-col class="col-md-6 text-md-right">
              <table-filter
                v-if="!paymentId"
                :table-name="'invoices'"
                :show-date-filter="true"
                :filters="tableFilters"
                :default-date-picker-date="defaultDatePickerDate"
                @filter="applyFilters"
                @filterLabelChange="onFilterLabelChange"
              />
            </v-col>
            <v-col class="col-md-3 text-md-right">
              <export-button
                :title="exportTitle"
                :headers="exportHeaders"
                :data-endpoint="constructCompleteInvoicesUrl"
                :orientation="'landscape'"
              />
            </v-col>
          </v-row>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="filteredSearchFilters"
                :active-filter="searchColumn"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="invoices"
        :loading="isInvoicesLoading"
        :server-items-length="pagination.totalItems"
        :options.sync="options"
        :footer-props="footerProps"
        class="clickable-data-table"
        @click:row="onRowClick"
      >
        <template v-slot:item.invoice_number="{ item }">
          {{ item.invoice_number }}
        </template>
        <template v-slot:item.customer.user.name="{ item }">
          {{ item.customer.user.name }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDay }}
        </template>
        <template v-slot:item.due_date="{ item }">
          {{ item.due_date | formatDay }}
        </template>
        <template v-slot:item.total_amount="{ item }">
          Ksh {{ item.total_amount | formatCurrency }}
        </template>
        <template v-slot:item.invoice_for="{ item }">
          {{ item.invoice_for.map(invoice => invoice.description).join(', ') }}
        </template>
        <template v-slot:item.status_description="{ item }">
          <v-chip
            class="ma-2"
            :color="getChipColor(item.status)"
          >
            {{ getStatusText(item.status) }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                icon
                v-bind="attrs"
                :loading="viewingInvoicesLoading[item.id]"
                :disabled="viewingInvoicesLoading[item.id]"
                @click.stop="viewInvoice(item.id)"
                v-on="on"
              >
                <v-icon v-bind="attrs">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View pdf</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                :loading="loadingInvoices[item.id]"
                :disabled="loadingInvoices[item.id]"
                @click.stop="downloadInvoice(item.id)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDownload }}
                </v-icon>
              </v-btn>
            </template>
            <span>Download pdf</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click.stop="sendInvoice(item)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiSend }}
                </v-icon>
              </v-btn>
            </template>
            <span>Send pdf</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click.stop="showDeleteInvoiceDialog(item.id)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <send-invoice-dialog
      :show-dialog="invoiceToSend.showDialog"
      :email="invoiceToSend.email"
      :invoice-id="invoiceToSend.invoiceId"
      :invoice-number="invoiceToSend.invoiceNumber"
      @close="invoiceToSend.showDialog = false"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :title="'Delete invoice?'"
      :agree-text="'Delete'"
      :message="'Are you sure you want to delete this invoice? This action can not be reversed.'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteInvoice()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {
  mdiEyeOutline, mdiDownload, mdiSend, mdiDelete,
} from '@mdi/js'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import TableFilter from '@/components/partials/table-filter/index.vue'
import ExportButton from '@/components/partials/ExportButton.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import SendInvoiceDialog from '@/components/dialogs/SendInvoiceDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    RefreshButton,
    TableFilter,
    ExportButton,
    SearchInput,
    SendInvoiceDialog,
    ConfirmDialog,
  },
  props: {
    invoicesUrl: {
      type: String,
      default: null,
    },
    customerId: {
      type: String,
      default: null,
    },
    paymentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      invoices: [],
      isInvoicesLoading: true,
      isInvoicesRefreshing: false,
      pagination: {
        totalItems: 0,
      },
      defaultDatePickerDate: 'Last 30 days',
      options: {
        sortBy: ['due_date'],
        sortDesc: [true],
      },
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100],
        'items-per-page-text': 'Items per page:',
      },
      search: '',
      tableFilters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
      searchFilters: [
        { text: 'Invoice Number', value: 'invoice_number' },
        { text: 'Customer Name', value: 'customer.user.name' },
        { text: 'Due Date', value: 'due_date' },
        { text: 'Total', value: 'total_amount' },
      ],
      headers: [
        { text: 'Invoice Number', value: 'invoice_number' },
        { text: 'Customer Name', value: 'customer.user.name' },
        { text: 'Invoice Date', value: 'created_at' },
        { text: 'Due Date', value: 'due_date' },
        { text: 'Total', value: 'total_amount' },
        { text: 'Invoice For', value: 'invoice_for', sortable: false },
        { text: 'Status', value: 'status_description' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      searchColumn: 'invoice_number',
      icons: {
        mdiEyeOutline,
        mdiDownload,
        mdiSend,
        mdiDelete,
      },
      loadingInvoices: {},
      viewingInvoicesLoading: {},
      invoiceToSend: {
        showDialog: false,
        email: '',
        invoiceId: '',
        invoiceNumber: '',
      },
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: '',
      exportTitle: 'Invoices',
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    constructCompleteInvoicesUrl() {
      const params = new URLSearchParams({
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDirection: this.options.sortDesc[0] ? 'desc' : 'asc',
        search: this.search,
        searchColumn: this.searchColumn,
        fromDate: this.tableFilters.dates.fromDate,
        toDate: this.tableFilters.dates.toDate,
      })

      if (this.customerId) {
        params.append('customerId', this.customerId)
      }
      if (this.paymentId) {
        params.append('paymentId', this.paymentId)
      }

      return `${this.invoicesUrl}?${params.toString()}`
    },
    filteredSearchFilters() {
      return this.searchFilters
    },
    exportHeaders() {
      return this.headers.filter(header => header.value !== 'actions')
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchInvoices()
      },
      deep: true,
    },
    search() {
      this.fetchInvoices()
    },
    selectedStation() {
      this.fetchInvoices()
    },
    searchColumn() {
      if (this.search) {
        this.fetchInvoices()
      }
    },
    customerId() {
      if (this.customerId) {
        this.defaultDatePickerDate = 'Last 1 year'
      }
      this.fetchInvoices()
    },
  },
  mounted() {
    if (this.customerId) {
      this.defaultDatePickerDate = 'Last 1 year'
    } else {
      this.defaultDatePickerDate = 'Last 30 days'
    }
    this.fetchInvoices()
  },
  methods: {
    fetchInvoices: _.debounce(function () {
      this.isInvoicesLoading = true

      axios
        .get(this.constructCompleteInvoicesUrl)
        .then(response => {
          this.invoices = response.data.data
          this.pagination.totalItems = response.data.total
        })
        .catch(error => {
          console.error('Error fetching invoices:', error)
        })
        .finally(() => {
          this.isInvoicesLoading = false
          this.isInvoicesRefreshing = false
        })
    }, 500),
    getChipColor(status) {
      switch (status) {
        case 1: // Paid
          return '#4CAF50' // Green
        case 2: // Overdue
          return '#F44336' // Red
        case 3: // Partially Paid
          return '#FFC107' // Yellow
        default: // Unpaid
          return '#9E9E9E' // Grey
      }
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return 'Paid'
        case 2:
          return 'Overdue'
        case 3:
          return 'Partially Paid'
        default:
          return 'Unpaid'
      }
    },
    refreshInvoices() {
      this.isInvoicesRefreshing = true
      this.fetchInvoices()
    },
    applyFilters(filters) {
      this.tableFilters = { ...this.tableFilters, ...filters }
      this.fetchInvoices()
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
    onRowClick(row) {
      this.$router.push({ name: 'invoices.show', params: { id: row.id } })
    },
    onFilterLabelChange(label) {
      this.exportTitle = label
    },
    viewInvoice(id) {
      this.$set(this.viewingInvoicesLoading, id, true)
      axios.get(`/invoices/${id}/download`, { responseType: 'blob' })
        .then(response => {
          const pdfBlob = response.data
          const pdfUrl = URL.createObjectURL(pdfBlob)
          window.open(pdfUrl, '_blank')
        })
        .catch(error => {
          console.error('Error viewing invoice:', error)

          // handle errors
        }).finally(() => {
          this.$set(this.viewingInvoicesLoading, id, false)
        })
    },
    sendInvoice(invoice) {
      this.invoiceToSend.showDialog = true
      this.invoiceToSend.email = invoice.customer.user.email
      this.invoiceToSend.invoiceId = invoice.id
      this.invoiceToSend.invoiceNumber = invoice.invoice_number
    },
    downloadInvoice(id) {
      this.$set(this.loadingInvoices, id, true)
      const url = `/invoices/${id}/download`

      axios({
        method: 'GET',
        url,
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)

        const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition']

        let filename = 'invoice.pdf' // default filename

        if (contentDisposition) {
          const filenameRegex = /filename="?([^"]+)"?\b/
          const matches = contentDisposition.match(filenameRegex)
          if (matches && matches[1]) {
            filename = matches[1]
          }
        }

        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(error => {
        console.error('There was an error downloading the invoice:', error)
        this.$toast.error('There was an error downloading the invoice')
      }).finally(() => {
        this.$set(this.loadingInvoices, id, false)
      })
    },
    showDeleteInvoiceDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deleteInvoice() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`invoices/${this.toDelete}`)
        .then(() => {
          this.refreshInvoices()
          this.$toast.success('Invoice deleted successfully')
        }).catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
  },
}
</script>
